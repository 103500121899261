<template>
  <div>
    <b-table
      id="remainders-table"
      no-border-collapse
      sticky-header="60vh"
      :fields="table.fields"
      :items="items"
      class="rems-table"
      :busy="table_busy"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div><strong>Загрузка...</strong></div>
        </div>
      </template>
      <template #cell(order)="{ index }">
        <span class="d-flex float-right">
          {{ +skip + index + 1 }}
        </span>
      </template>
      <template #cell(product)="{ item }">
        <div style="display: flex; flex-direction: column">
          {{ item.product?.name }}
          <div class="product-info-div">
            <span class="mr-2">{{ item.product?.articul }}</span
            ><span class="supplier">
              {{ item.product.supplier?.name ?? '-' }}
            </span>
          </div>
        </div>
      </template>
      <template #cell(storage)="{ item }">
        {{ item.storage.name }}
      </template>
      <template #cell(measurement)="{ item }">
        <span class="d-flex float-left">
          {{ item.product.measurement?.name ?? '-' }}
        </span>
      </template>
      <template #cell(remainders)="{ item }">
        <span class="d-flex float-right">
          {{ (+item.end.remains.toFixed(3))?.toLocaleString('ru-RU') || 0 }}
        </span>
      </template>
      <template #cell(category)="{ item }">
        {{ item.product.category?.name ?? '-' }}
      </template>
      <template #cell(purchasePrice)="{ item }">
        <span class="d-flex float-right">
          {{
            item.end.remains === 0
              ? 0
              : (+(item.end.purchase / item.end.remains).toFixed(2))?.toLocaleString('ru-RU') || 0
          }}
        </span>
      </template>
      <template #cell(retailPrice)="{ item }">
        <span class="d-flex float-right">
          {{
            item.end.remains === 0
              ? 0
              : (+(item.end.retail / item.end.remains).toFixed(2))?.toLocaleString('ru-RU') || 0
          }}
        </span>
      </template>
      <template #cell(purchaseSum)="{ item }">
        <span class="d-flex float-right">
          {{ (+item.end.purchase?.toFixed(2))?.toLocaleString('ru-RU') || 0 }}
        </span>
      </template>
      <template #cell(retailSum)="{ item }">
        <span class="d-flex float-right">
          {{ (+item.end.retail?.toFixed(2))?.toLocaleString('ru-RU') || 0 }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name: 'RemaindersTable',
    props: {
      items: {
        type: Array,
        default: () => []
      },
      skip: {
        type: Number,
        default: () => 0
      },
      table_busy: {
        type: Boolean,
        default: () => true
      }
    },
    data() {
      return {
        table: {
          busy: true,
          fields: [
            { key: 'order', label: '№' },
            { key: 'product', label: 'Наименование товара' },
            { key: 'category', label: 'Товарная группа' },
            { key: 'storage', label: 'Место хранения' },
            { key: 'measurement', label: 'Ед. изм.' },
            { key: 'remainders', label: 'Остатки' },
            { key: 'purchasePrice', label: 'Цена закупочная, ₽' },
            { key: 'retailPrice', label: 'Цена розничная, ₽' },
            { key: 'purchaseSum', label: 'Сумма закупочная, ₽' },
            { key: 'retailSum', label: 'Сумма розничная, ₽' }
          ]
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .rems-table {
    margin-bottom: 0;
    width: 100%;
    min-width: 100%;
  }

  @media screen and (max-height: 800px) {
    .rems-table {
      margin-bottom: 0;
      height: 45vh;
    }
  }

  ::v-deep() {
    .rems-table thead th {
      border-bottom: 1px solid #e1e1e1 !important;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .rems-table tbody tr td {
      border-bottom: 1px solid #e1e1e1 !important;
    }
  }

  .product-info-div {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 380px;

    & > span {
      color: #888888;
      font-weight: 500;
    }

    & .supplier {
      color: #888888;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-height: 1rem;
    }
  }
</style>
