import { render, staticRenderFns } from "./RemaindersTable.vue?vue&type=template&id=740a81fc&scoped=true&"
import script from "./RemaindersTable.vue?vue&type=script&lang=js&"
export * from "./RemaindersTable.vue?vue&type=script&lang=js&"
import style0 from "./RemaindersTable.vue?vue&type=style&index=0&id=740a81fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740a81fc",
  null
  
)

export default component.exports