<template>
  <section
    id="remainder-report"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <categories-load-modal
      :included_categories="selectedCategories"
      @add-categories="addCategories"
    />
    <!-- <h1>Остатки</h1> -->
    <div
      class="d-flex justify-content-between flex-wrap"
      style=""
    >
      <div class="filters-row align-items-center">
        <div class="filter-item data">
          <date-picker
            v-model="selectedDate"
            format="dd.MM.yyyy"
            class="elm-calendar"
            placeholder="Выберите дату"
            :clearable="false"
          />
        </div>
        <div
          class="filter-item"
          @click.capture="showLoadModal"
        >
          <div class="categories-select">
            <span style="color: black">{{ categoriesCount }}</span>
            <img
              src="/img/icons/arrow_up.svg"
              style="transform: rotate(180deg)"
            />
          </div>
        </div>
        <div class="filter-item">
          <i-select
            v-model="selectedSupplier"
            filterable
            placeholder="Все поставщики"
            multiple
          >
            <i-option
              v-for="option of suppliers"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </i-option>
          </i-select>
        </div>
        <div class="filter-item">
          <i-select
            id="targetStorage"
            v-model="selectedStorage"
            multiple
            transfer-class-name="my-dropdown"
            transfer
          >
            <i-option-group
              v-for="item in branchList"
              :key="item.id"
              class="option-group"
              :value="item.id"
              :label="item.name"
            >
              <i-option
                v-for="el in item.storage"
                :key="el.id"
                :value="el.id"
              >
                {{ el.name }}
              </i-option>
            </i-option-group>
          </i-select>
        </div>
        <div
          class="clear-text"
          @click="clearFilters"
        >
          Сбросить
        </div>
      </div>
      <div class="reminder-header-row">
        <e-button
          size="m"
          @click="downloadExel"
        >
          <img
            style="margin-right: 12px"
            src="/img/icons/download.svg"
            alt="download"
          />Скачать
        </e-button>
        <b-button
          class="ml-auto"
          variant="light"
          @click="printList"
        >
          Печать
        </b-button>
        <!--      <b-button class="ml-2" variant="light">Скачать</b-button>-->
      </div>
    </div>
    <!--    <remainder-period :period="data_range" />-->
    <b-row style="height: 100%">
      <b-col
        cols="12"
        class="d-flex flex-column"
        :class="table.total > 20 ? 'card-height' : ''"
      >
        <b-card
          class="p-0 m-0 card-size"
          no-body
          style="overflow: hidden; display: flex; flex: 1"
        >
          <div class="analytics-table-header">
            <div class="table-filter">
              <analytics-product-search
                :products="products"
                @input="setProducts"
              />
            </div>
          </div>
          <remainders-table
            :items="table.items"
            :skip="table.pagination.skip"
            style="max-height: none; min-height: 1px; display: flex"
            :table_busy="$apollo.queries.GetRemainders.loading"
          />
        </b-card>
        <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
          <div class="mr-auto"></div>

          <div class="footer-data">
            Общее количество:
            {{ (+table.total_delta?.toFixed(3))?.toLocaleString('ru-RU') || 0 }}
          </div>

          <div class="footer-data">
            Сумма в закупочных ценах:
            <span>
              {{ (+table.total_purchase_sum?.toFixed(2))?.toLocaleString('ru-RU') || 0 }}
              ₽
            </span>
          </div>

          <div class="footer-data">
            Сумма в розничных ценах:
            <span>
              {{ (+table.total_retail_sum.toFixed(2))?.toLocaleString('ru-RU') || 0 }}
              ₽
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
    <pagination
      :total="table.total"
      table_name="remainders-table"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import RemaindersTable from '@/views/analytics/components/RemaindersTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapGetters } from 'vuex'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import { http } from '@/http/http'
  import { print_html } from '@/utils/print'
  import AnalyticsProductSearch from '@/views/analytics/components/AnalyticsProductSearch.vue'
  export default {
    name: 'Remainders',
    components: {
      AnalyticsProductSearch,
      CategoriesLoadModal,
      RemaindersTable,
      Pagination
    },
    data() {
      return {
        suppliers: [],
        branchList: [],
        products: [],
        storage_list: [],
        selectedDate: new Date(),
        selectedSupplier: [],
        selectedStorage: [],
        searchString: '',
        selectedCategories: [],
        table: {
          pagination: {
            skip: 0,
            take: 20
          },
          total: 0,
          total_retail_sum: 0,
          total_purchase_sum: 0,
          total_delta: 0,
          items: []
        }
      }
    },
    apollo: {
      GetRemainders: {
        query: require('../gql/Remainders.graphql'),
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            input: {
              branch: this.currentBranch.id || '1',
              branches: this.currentBranch.branches?.map((el) => el.id) || [],
              period: {
                begin: this.selectedDate,
                end: this.selectedDate
              },
              supplier: this.selectedSupplier,
              storage: this.selectedStorage,
              category: this.selectedCategories,
              products: this.products?.map((el) => el.id) || [],
              byCategory: false,
              pagination: {
                order: null,
                search: this.searchString,
                skip: this.table.pagination.skip,
                take: this.table.pagination.take
              }
            }
          }
        },
        // skip: () => true,
        result({ data }) {
          if (!data?.GetRemainders) return
          this.table.items = data?.GetRemainders?.analytics ?? []
          this.table.total = data?.GetRemainders?.total ?? 0
          this.table.total_retail_sum = data?.GetRemainders?.total_retail_sum ?? 0
          this.table.total_purchase_sum = data?.GetRemainders?.total_purchase_sum ?? 0
          this.table.total_delta = data?.GetRemainders?.total_delta ?? 0
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      Suppliers: {
        query: require('../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers = data.Suppliers ?? []
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Branches: {
        query: require('../gql/getBranches.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.branchList = data.Branches || []
        }
      }
    },
    methods: {
      setProducts(prods) {
        this.products = prods
      },
      async downloadExel() {
        const { data } = await this.$apollo.query({
          query: require('../gql/GetRemaindersExel.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              branch: this.currentBranch.id,
              period: {
                begin: this.selectedDate,
                end: this.selectedDate
              },
              supplier: this.selectedSupplier,
              storage: this.selectedStorage,
              category: this.selectedCategories,
              products: this.products?.map((el) => el.id) || [],
              byCategory: false
            }
          }
        })
        if (data?.GetRemaindersExel) {
          const link = document.createElement('a')
          link.href = data?.GetRemaindersExel?.url
          link.download = 'exel'
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          this.$noty.error('Ошибка при загруке файла')
        }
      },
      clearFilters() {
        this.selectedSupplier = []
        this.selectedStorage = []
        this.selectedCategories = []
        this.selectedDate = new Date()
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.table.pagination.skip = Number((current_page - 1) * take)
        if (take) this.table.pagination.take = take
      },
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      addCategories(cats) {
        this.selectedCategories = cats
      },
      async printList() {
        try {
          const { data } = await http.post('templates/reports/remainders', {
            branch: this.currentBranch.id || '1',
            period: {
              begin: this.selectedDate,
              end: this.selectedDate
            },
            supplier: this.selectedSupplier,
            storage: this.selectedStorage,
            category: this.selectedCategories
          })
          if (data) {
            print_html(data)
          } else {
            this.$noty.error('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка печати')
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      categoriesCount() {
        if (this.selectedCategories.length) {
          return 'Выбрано ' + this.selectedCategories.length
        }
        return 'Все группы товаров'
      }
    }
  }
</script>

<style scoped lang="scss">
  #remainder-report {
    .categories-select {
      display: flex;
      flex-direction: row;
      background: white;
      border: 1px solid #bcbcbc;
      border-radius: 2px;
      height: 32px;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      justify-content: space-between;
    }

    ::v-deep() {
      .b-filter-date {
        width: 208px;
        margin-top: 32px;
      }

      .chart-block {
        display: flex;
        flex: 1;
        max-height: 100%;

        .card {
          flex: 1;
          max-height: 100%;
          margin-bottom: 0px;

          .card-body {
            max-height: 100%;
          }
        }
      }

      .widget-height {
        height: 350px;
        margin-bottom: 16px;
      }

      .widget-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #313131;
      }
    }
  }

  ::v-deep() {
    .reminder-header-row {
      button {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }

    .analytics-header-select {
      width: 260px;
    }

    .widget-row {
      display: flex;
    }

    .widget-total {
      white-space: nowrap;
      display: flex;
    }

    .analytics__item {
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #313131;

      &-title {
        font-size: 14px;
        line-height: 16px;
        color: #888888;
        margin-bottom: 8px;
      }
    }

    .analytics-table-header {
      input.filter-search {
        width: 350px;
        padding-left: 32px;
        background: url(/img/icons/input-search.svg) 12px center no-repeat;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .table-footer th {
      vertical-align: middle !important;
    }
  }

  .reminder-header-row {
    display: flex;
    align-items: center;
    button {
      margin-right: 16px;
      margin-bottom: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
    //margin-bottom: 16px;
  }

  .filters-row {
    flex: 1;
    height: fit-content !important;
    margin-top: 0 !important;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .clear-text {
      margin-right: 16px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    .filter-item {
      max-width: 300px;
      min-width: 190px;
      width: 100%;
      margin-right: 16px;
      margin-bottom: 16px;
      &.data {
        min-width: 130px;
        max-width: 130px;
      }
    }
  }

  .analytics-table-header {
    padding: 16px;
  }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }
  .incoming-table-footer {
    background-color: white;
    border: 1px solid #e1e1e1 !important;
    border-top: none !important;
  }

  .card-height {
    max-height: 66vh;
  }

  @media screen and (max-height: 800px) {
    .card-size {
      height: 52vh;
    }

    .card-height {
      max-height: 60vh;
    }
  }
</style>
