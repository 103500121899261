var render = function render(){
  var _vm$table$total_delta, _vm$table$total_delta2, _vm$table$total_purch, _vm$table$total_purch2, _vm$table$total_retai;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "height": "100%"
    },
    attrs: {
      "id": "remainder-report"
    }
  }, [_c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.selectedCategories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between flex-wrap"
  }, [_c('div', {
    staticClass: "filters-row align-items-center"
  }, [_c('div', {
    staticClass: "filter-item data"
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "format": "dd.MM.yyyy",
      "placeholder": "Выберите дату",
      "clearable": false
    },
    model: {
      value: _vm.selectedDate,
      callback: function ($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('div', {
    staticClass: "filter-item",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select"
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Все поставщики",
      "multiple": ""
    },
    model: {
      value: _vm.selectedSupplier,
      callback: function ($$v) {
        _vm.selectedSupplier = $$v;
      },
      expression: "selectedSupplier"
    }
  }, _vm._l(_vm.suppliers, function (option) {
    return _c('i-option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "id": "targetStorage",
      "multiple": "",
      "transfer-class-name": "my-dropdown",
      "transfer": ""
    },
    model: {
      value: _vm.selectedStorage,
      callback: function ($$v) {
        _vm.selectedStorage = $$v;
      },
      expression: "selectedStorage"
    }
  }, _vm._l(_vm.branchList, function (item) {
    return _c('i-option-group', {
      key: item.id,
      staticClass: "option-group",
      attrs: {
        "value": item.id,
        "label": item.name
      }
    }, _vm._l(item.storage, function (el) {
      return _c('i-option', {
        key: el.id,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1);
  }), 1)], 1), _c('div', {
    staticClass: "clear-text",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" Сбросить ")])]), _c('div', {
    staticClass: "reminder-header-row"
  }, [_c('e-button', {
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.downloadExel
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      "src": "/img/icons/download.svg",
      "alt": "download"
    }
  }), _vm._v("Скачать ")]), _c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.printList
    }
  }, [_vm._v(" Печать ")])], 1)]), _c('b-row', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('b-col', {
    staticClass: "d-flex flex-column",
    class: _vm.table.total > 20 ? 'card-height' : '',
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-0 m-0 card-size",
    staticStyle: {
      "overflow": "hidden",
      "display": "flex",
      "flex": "1"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "analytics-table-header"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('analytics-product-search', {
    attrs: {
      "products": _vm.products
    },
    on: {
      "input": _vm.setProducts
    }
  })], 1)]), _c('remainders-table', {
    staticStyle: {
      "max-height": "none",
      "min-height": "1px",
      "display": "flex"
    },
    attrs: {
      "items": _vm.table.items,
      "skip": _vm.table.pagination.skip,
      "table_busy": _vm.$apollo.queries.GetRemainders.loading
    }
  })], 1), _c('div', {
    staticClass: "table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Общее количество: " + _vm._s(((_vm$table$total_delta = +((_vm$table$total_delta2 = _vm.table.total_delta) === null || _vm$table$total_delta2 === void 0 ? void 0 : _vm$table$total_delta2.toFixed(3))) === null || _vm$table$total_delta === void 0 ? void 0 : _vm$table$total_delta.toLocaleString('ru-RU')) || 0) + " ")]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма в закупочных ценах: "), _c('span', [_vm._v(" " + _vm._s(((_vm$table$total_purch = +((_vm$table$total_purch2 = _vm.table.total_purchase_sum) === null || _vm$table$total_purch2 === void 0 ? void 0 : _vm$table$total_purch2.toFixed(2))) === null || _vm$table$total_purch === void 0 ? void 0 : _vm$table$total_purch.toLocaleString('ru-RU')) || 0) + " ₽ ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма в розничных ценах: "), _c('span', [_vm._v(" " + _vm._s(((_vm$table$total_retai = +_vm.table.total_retail_sum.toFixed(2)) === null || _vm$table$total_retai === void 0 ? void 0 : _vm$table$total_retai.toLocaleString('ru-RU')) || 0) + " ₽ ")])])])], 1)], 1), _c('pagination', {
    attrs: {
      "total": _vm.table.total,
      "table_name": "remainders-table"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }